<template>
  <div v-if="triggerUnderpaid === true">
    <!--Divider-->
    <v-row class="my-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <!--Underpayments-->
    <v-row>
      <v-col sm="6" md="6">
        <h4>Underpayments Allocated</h4>
      </v-col>
    </v-row>
    <div v-for="item in this.sales_orders" :key="item.order_id">
      <!--Only show sales orders with underpaid amounts-->
      <v-row
        v-if="
          item.order_allocated_portion.some(
            (portion) => isNaN(portion.underpaid) === false
          )
        "
      >
        <v-col
          sm="3"
          md="3"
          v-for="data in [
            { name: item.order_number },
            { name: formatAsCurrency('R', returnUnderpaidAmount(item)) },
          ]"
          :key="data.name"
        >
          {{ data.name }}
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import {
  returnUnderpaidAmount,
  triggerUnderpaid,
} from "../data/external_customer_payment_allocation";
import { formatAsCurrency } from "../../../composables/external";

export default {
  name: "CustomerPaymentAllocationSummaryUnderpaid",
  props: ["sales_orders", "customer_payment_allocation_document"],
  data() {
    return {};
  },
  computed: {
    triggerUnderpaid,
  },
  methods: {
    formatAsCurrency,
    returnUnderpaidAmount,
  },
};
</script>
